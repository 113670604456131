<template>
  <v-card>
    <v-card-title>
      <span class="text-h5">Экспорт показателей</span>
      <a class="close" @click="$emit('close')">
        <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
          <path d="M19 6.41L17.59 5L12 10.59L6.41 5L5 6.41L10.59 12L5 17.59L6.41 19L12 13.41L17.59 19L19 17.59L13.41 12L19 6.41Z" fill="#0033A0"/>
        </svg>
      </a>
    </v-card-title>

    <v-card-text>
      <div class="rating-popup">
        <v-container>
          <v-row>
            <v-col cols="12">
              <h4>Формат</h4>
              <v-radio-group v-model="exportType" row>
                <v-radio label="XLSX" value="xlsx"></v-radio>
                <v-radio label="PDF" value="pdf"></v-radio>
              </v-radio-group>
            </v-col>
          </v-row>

          <v-row>
            <v-col cols="12">
              <h4>Фильтры</h4>
            </v-col>
            <v-col cols="12" sm="12">
              <v-autocomplete
                  label="Мониторинг"
                  v-model="config"
                  :items="configs"
                  item-text="content.title"
                  return-object
                  @input="configChanged(); errorTextMonitoring = null"
              ></v-autocomplete>
              <span v-if="errorTextMonitoring" class="validate-error">{{ errorTextMonitoring }}</span>
            </v-col>

            <template v-if="config?.content.type === 'region'">
              <v-col cols="6" sm="12">
                <div>Уровень выбранного мониторинга: "Регионы" <br> Укажите регион</div>
              </v-col>
              <v-col cols="12" sm="12">
                <v-select
                    label="Регионы"
                    :items="regions"
                    item-text="name"
                    return-object
                    multiple
                    v-model="selectedRegions"
                    @change="errorTextRegion = null"
                >
                  <template v-slot:selection="{ item, index }">
                    <v-chip v-if="index === 0">
                      <span>{{ item.name }}</span>
                    </v-chip>
                    <span
                        v-if="index === 1"
                        class="grey--text text-caption"
                    >
                    (+{{ selectedRegions.length - 1 }})
                  </span>
                  </template>
                  <template v-slot:prepend-item>
                    <v-list-item
                        ripple
                        @mousedown.prevent
                        @click="toggleRegions()"
                    >
                      <v-list-item-action>
                        <v-icon class="icon-item"
                                :color="selectedRegions.length > 0 ? 'indigo darken-4' : ''"
                                :class="regionsIcon"
                        >
                        </v-icon>
                      </v-list-item-action>
                      <v-list-item-content>
                        <v-list-item-title>
                          Выбрать все
                        </v-list-item-title>
                      </v-list-item-content>
                    </v-list-item>
                    <v-divider class="mt-2"></v-divider>
                  </template>
                </v-select>
                <span v-if="errorTextRegion" class="validate-error">{{ errorTextRegion }}</span>
              </v-col>
            </template>

            <template v-else-if="config?.content.type === 'czn'">
              <v-col cols="6" sm="12">
                <div>Уровень выбранного мониторинга: "ЦЗН" <br> Укажите сначала регион, а затем выберите ЦЗН</div>
              </v-col>
              <v-col cols="12" sm="12">
                <v-select
                    label="Регионы"
                    v-model="selectedDepartmentsRegions"
                    :items="departmentsRegions"
                    item-text="name"
                    return-object
                    multiple
                    @input="updateDepartments(); errorTextCznRegion = null"
                >
                  <template v-slot:selection="{ item, index }">
                    <v-chip v-if="index === 0">
                      <span>{{ item.name }}</span>
                    </v-chip>
                    <span
                        v-if="index === 1"
                        class="grey--text text-caption"
                    >
                    (+{{ selectedDepartmentsRegions.length - 1 }})
                  </span>
                  </template>
                  <template v-slot:prepend-item>
                    <v-list-item
                        ripple
                        @mousedown.prevent
                        @click="toggleDepartmentsRegions()"
                    >
                      <v-list-item-action>
                        <v-icon class="icon-item"
                                :class="departmentsRegionsIcon"
                                :color="selectedDepartmentsRegions.length > 0 ? 'indigo darken-4' : ''">
                        </v-icon>
                      </v-list-item-action>
                      <v-list-item-content>
                        <v-list-item-title>
                          Выбрать все
                        </v-list-item-title>
                      </v-list-item-content>
                    </v-list-item>
                    <v-divider class="mt-2"></v-divider>
                  </template>
                </v-select>
                <span v-if="errorTextCznRegion" class="validate-error">{{ errorTextCznRegion }}</span>
              </v-col>
              <v-col cols="12" sm="12">
                <v-select
                    label="ЦЗН"
                    v-model="selectedDepartments"
                    :items="departments"
                    item-text="name"
                    return-object
                    multiple
                    @change="errorTextCznDepartment = null"
                >
                  <template v-slot:selection="{ item, index }">
                    <v-chip v-if="index === 0">
                      <span>{{ item.name }}</span>
                    </v-chip>
                    <span
                        v-if="index === 1"
                        class="grey--text text-caption"
                    >
                    (+{{ selectedDepartments.length - 1 }})
                  </span>
                  </template>
                  <template v-slot:prepend-item>
                    <v-list-item
                        ripple
                        @mousedown.prevent
                        @click="toggleDepartments()"
                    >
                      <v-list-item-action>
                        <v-icon class="icon-item"
                                :class="departmentsIcon"
                                :color="selectedDepartments.length > 0 ? 'indigo darken-4' : ''">
                        </v-icon>
                      </v-list-item-action>
                      <v-list-item-content>
                        <v-list-item-title>
                          Выбрать все
                        </v-list-item-title>
                      </v-list-item-content>
                    </v-list-item>
                    <v-divider class="mt-2"></v-divider>
                  </template>
                </v-select>
                <span v-if="errorTextCznDepartment" class="validate-error">{{ errorTextCznDepartment }}</span>
              </v-col>
            </template>
          </v-row>
          <v-row>
            <v-col cols="12">
              <h4>Сортировка</h4>
            </v-col>
            <v-col cols="12" sm="12">
              <v-select
                  dense
                  label="По имени"
                  :items="sortList"
                  item-text="name"
                  item-value="value"
                  v-model="ascendingByName"
                  @change="errorTextAscending = null"
              ></v-select>
              <span v-if="errorTextAscending" class="validate-error">{{ errorTextAscending }}</span>
            </v-col>
          </v-row>
        </v-container>
      </div>
    </v-card-text>

    <v-card-actions>
      <v-spacer></v-spacer>
      <v-btn
          class="btn btn-outline"
          text
          @click="$emit('close')"
      >
        Закрыть
      </v-btn>
      <v-btn
          class="btn btn-primary"
          @click="submit()"
      >
        Скачать
      </v-btn>
      <div
          v-if="errorText != null"
          class="error"
          style="position: fixed; bottom: 0"
      >
        Ошибка: {{ errorText }}
      </div>
    </v-card-actions>
    <LoaderDialog :show="loading" msg="Загрузка"></LoaderDialog>
  </v-card>
</template>

<script>
import {getDomainConfigs} from "@/modules/api.configs";
import api from "@/modules/api";
import LoaderDialog from "@/components/elements/LoaderDialog.vue";

export default {
  name: 'StandardExportDialog',
  components: {LoaderDialog},
  data: () => ({
    exportType: "xlsx",
    domain: 'standard_report',
    configs: [],
    config: null,
    loading: false,
    regions: [],
    departments: [],
    departmentsRegions: [],
    selectedRegions: [],
    selectedDepartmentsRegions: [],
    selectedDepartments: [],
    ascendingByName: null,
    downloadTaskId: null,
    errorTextMonitoring: null,
    errorTextAscending: null,
    errorTextRegion: null,
    errorTextCznRegion: null,
    errorTextCznDepartment: null,
    errorText: null,
    sortList: [
      {name: "В алфавитном порядке", value: true},
      {name: "В обратном алфавитном порядке", value: false}
    ],
  }),
  computed: {
    selectedAllRegions() {
      return this.selectedRegions.length === this.regions.length
    },
    selectedSomeRegions() {
      return this.selectedRegions.length > 0 && !this.selectedAllRegions
    },
    regionsIcon() {
      if (this.selectedAllRegions) return 'icon-close-box'
      if (this.selectedSomeRegions) return 'icon-minus-box'
      return 'icon-checkbox-blank-outline'
    },
    selectedAllDepartmentsRegions() {
      return this.selectedDepartmentsRegions.length === this.departmentsRegions.length
    },
    selectedSomeDepartmentsRegions() {
      return this.selectedDepartmentsRegions.length > 0 && !this.selectedAllDepartmentsRegions
    },
    departmentsRegionsIcon() {
      if (this.selectedAllDepartmentsRegions) return 'icon-close-box'
      if (this.selectedSomeDepartmentsRegions) return 'icon-minus-box'
      return 'icon-checkbox-blank-outline'
    },
    selectedAllDepartments() {
      return this.selectedDepartments.length === this.departments.length
    },
    selectedSomeDepartments() {
      return this.selectedDepartments.length > 0 && !this.selectedAllDepartments
    },
    departmentsIcon() {
      if (this.selectedAllDepartments) return 'icon-close-box'
      if (this.selectedSomeDepartments) return 'icon-minus-box'
      return 'icon-checkbox-blank-outline'
    },
  },
  methods: {
    async loadConfigs() {
      this.configs = (await getDomainConfigs(this.domain)).payload
      this.configs.sort((a, b) => (a.id < b.id ? 1 : -1))
    },
    configChanged() {
      this.populateRegions()
      if (this.config.content.type === "czn") {
        this.populateCzns()
      }
      this.selectedRegions = [];
      this.selectedDepartmentsRegions = [];
      this.selectedDepartments = [];
      this.ascendingByName = null;
    },
    populateRegions() {
      if (this.config?.content?.regions) {
        this.regions = this.config.content.regions.map(regionCode => ({
          code: regionCode,
          name: this.getRegionNameByCode(regionCode)
        }))
      }
    },
    populateCzns() {
      const map = new Map()
      for (const cznId of this.config.content.czns) {
        const czn = this.findCznById(cznId);
        if (czn) {
          if (!map.has(czn.region.code)) {
            map.set(czn.region.code, {
              name: czn.region.name,
              code: czn.region.code,
              departments: [czn]
            });
          } else {
            map.get(czn.region.code).departments.push(czn);
          }
        }
      }
      const departmentsByRegion = Array.from(map.values());

      departmentsByRegion.sort((a, b) => a.name.localeCompare(b.name));
      departmentsByRegion.forEach(region => {
        region.departments.sort((a, b) => a.name.localeCompare(b.name));
      });

      this.departmentsRegions = departmentsByRegion;
    },
    updateDepartments() {
      let czns = []
      for (let region of this.selectedDepartmentsRegions) {
        czns = czns.concat(region.departments)
      }
      this.departments = czns
      this.selectedDepartments = []
    },
    toggleRegions() {
      this.$nextTick(() => {
        if (this.selectedAllRegions) {
          this.selectedRegions = [];
        } else {
          this.selectedRegions = this.regions;
        }
      })
    },
    toggleDepartmentsRegions() {
      this.$nextTick(() => {
        if (this.selectedAllDepartmentsRegions) {
          this.selectedDepartmentsRegions = []
        } else {
          this.selectedDepartmentsRegions = this.departmentsRegions.slice()
        }
        this.updateDepartments()
      })
    },
    toggleDepartments() {
      this.$nextTick(() => {
        if (this.selectedAllDepartments) {
          this.selectedDepartments = [];
        } else {
          this.selectedDepartments = this.departments.slice();
        }
      })
    },
    getRegionNameByCode(regionCode) {
      return this.$cznDepartmentsByRegionList.find(r => r.code === regionCode)?.name
    },
    findCznById(cznId) {
      for (const region of this.$cznDepartmentsByRegionList) {
        const czn = region.departments.find(c => c.id === cznId)
        if (czn) {
          return czn
        }
      }
      return null
    },
    async submit() {
      if (this.validateSubmit()) {
        await this.downloadReport()
      }
    },
    async downloadReport() {
      try {
        this.loading = true
        let params = {
          config_id: this.config.id,
          format: this.exportType,
          sort: this.ascendingByName ? 'asc' : 'desc',
        }

        if (this.config.content.type === "region") {
          const regions = this.getArrayOfCodes(this.selectedRegions);
          if (regions.length > 0) {
            params.region_codes = regions;
          }
        }
        if (this.config.content.type === "czn") {
          const czns = this.getArrayOfIds(this.selectedDepartments);
          if (czns.length > 0) {
            params.czn_ids = czns;
          }
        }

        const queryString = new URLSearchParams()
        for (const key in params) {
          if (Array.isArray(params[key])) {
            params[key].forEach(value => queryString.append(key, value));
          } else {
            queryString.append(key, params[key]);
          }
        }

        const response = (await api.get(`/reports/generate/${this.domain}/1?${queryString}`)).payload
        this.downloadTaskId = response.task_id
        await this.checkTaskStatus()
      } catch (error) {
        console.error('Error generating report:', error)
        alert('Ошибка при генерации отчета!')
        this.loading = false
      }
    },
    async checkTaskStatus() {
      try {
        const response = (await api.get(`/reports/status/${this.downloadTaskId}`)).payload
        if (response.status === 'SUCCESS') {
          await this.downloadFile()
        } else {
          setTimeout(this.checkTaskStatus, 2000)
        }
      } catch (error) {
        console.error('Error checking task status:', error)
        alert('Ошибка при проверке статуса задачи!')
        this.loading = false
      }
    },
    async downloadFile() {
      try {
        const blob = (await api.get(`/reports/download/${this.downloadTaskId}`, null, 'blob')).payload
        await this.$downloadBlob(blob, `${this.getReportTitle()}.${this.exportType}`)
      } catch (error) {
        console.error('Error downloading file:', error)
        alert('Ошибка при скачивании файла!')
      } finally {
        this.$emit('close')
        this.loading = false
      }
    },
    getReportTitle() {
        return this.config.content.title
    },
    validateSubmit() {
      if (this.config == null) {
        this.errorTextMonitoring = "Укажите мониторинг";
        return false
      }
      if (this.config.content.type === "region") {
        if (this.selectedRegions.length === 0) {
          this.errorTextRegion = "Укажите регион";
          return false
        }
      } else if (this.config.content.type === "czn") {
        if (this.selectedDepartmentsRegions.length === 0) {
          this.errorTextCznRegion = "Укажите регион";
          return false
        }
        if (this.selectedDepartments.length === 0) {
          this.errorTextCznDepartment = "Укажите ЦЗН";
          return false
        }
      }
      if (this.ascendingByName == null) {
        this.errorTextAscending = "Укажите сортировку";
        return false
      }
      return true
    },
    getArrayOfCodes(arr) {
      let arrayOfNames = [];
      for (let a of arr) {
        arrayOfNames.push(a.code)
      }
      return arrayOfNames
    },
    getArrayOfIds(arr) {
      let arrayOfNames = [];
      for (let a of arr) {
        arrayOfNames.push(a.id)
      }
      return arrayOfNames
    },
  },
  async beforeMount() {
    await this.loadConfigs()
  },
}
</script>

<style lang="scss" scoped>
.validate-error {
  font-style: normal;
  font-weight: 500;
  line-height: 100%;
  font-size: 12px;
  color: red;
}
</style>
